import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';
import { isValidEmail } from '../../../../utils/form-validations';
import { AppliedDiscount } from '@wix/ambassador-totals-calculator/http';
import { FormStatus } from '../../../../types/form-state';
import { isFixedPrice } from '../../../../utils/payment/payment';
import { PriceInfo } from '@wix/ambassador-bookings-v2-price-info/types';
import {
  mapPriceInfoToPaymentDetails,
  mapVariantsToLineItems,
} from '../../../../utils/mappers/dynamic-price.mapper';
import { FormApi } from '../../../../api/FormApi';
import { SelectedVariants } from '@wix/bookings-uou-types';

export type CalculatePaymentDetails = ({
  couponCode,
}: {
  couponCode?: string;
}) => Promise<void>;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers,
}: {
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>;
  errorHandlers: ErrorHandlers;
}): CalculatePaymentDetails {
  return async ({ couponCode }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi, reportError, experiments } = actionFactoryParams.context;
    const {
      formInputs: { numberOfParticipants, email },
      slotAvailability,
      service,
      selectedPaymentType,
      isBookingsOnEcom,
      dynamicPriceInfo,
      businessInfo,
    } = state;
    const { id: serviceId, rate, payment } = service;
    const slot = slotAvailability?.slot!;

    setState({
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    const isFormDisplayPaymentdetailsWithDepositEnabled = experiments.enabled(
      'specs.bookings.EcomFormDisplayPaymentdetailsWithDeposit',
    );
    try {
      if (isBookingsOnEcom) {
        let priceInfo: PriceInfo = {};
        if (dynamicPriceInfo?.selectedVariants) {
          priceInfo = await previewPrice({
            serviceId,
            resourceId: slot.resource?.id!,
            selectedVariants: dynamicPriceInfo.selectedVariants,
            formApi,
          });

          if (priceInfo?.bookingLineItems?.length) {
            const dynamicPriceOptions = dynamicPriceInfo
              .serviceOptionsAndVariants?.options!;
            const locale = businessInfo.dateRegionalSettingsLocale!;
            const paymentDetails = mapPriceInfoToPaymentDetails({
              priceInfo,
              dynamicPriceOptions,
              locale,
            });

            setState({
              dynamicPriceInfo: {
                ...dynamicPriceInfo,
                paymentDetails,
              },
            });
          }
        }
        let price: number;
        let deposit: number | undefined;
        if (priceInfo?.bookingLineItems?.length) {
          price =
            priceInfo?.calculatedPrice ||
            Number(dynamicPriceInfo?.defaultPrice?.amount!);
          if (isFormDisplayPaymentdetailsWithDepositEnabled) {
            deposit = priceInfo?.deposit!;
          }
        } else {
          price = service.payment.paymentDetails.price;
          deposit = isFormDisplayPaymentdetailsWithDepositEnabled
            ? state.paymentDetails.minCharge
            : undefined;
        }

        const {
          priceSummary,
          appliedDiscounts,
          payNow,
          payLater,
        } = await formApi.calculateTotalPrice({
          serviceId,
          price,
          deposit,
          numberOfParticipants,
          couponCode,
          email: isValidEmail(email) ? email : undefined,
          selectedPaymentType,
          isDynamicPrice: !!priceInfo?.calculatedPrice,
          onError: (error) => {
            errorHandlers.addError(error);
          },
        });

        if (!appliedDiscounts) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        const [couponDiscount] = appliedDiscounts!;
        setState({
          couponInfo: {
            ...state.couponInfo,
            appliedCoupon: mapCouponDiscountToAppliedCoupon(couponDiscount),
          },
          paymentDetails: {
            ...state.paymentDetails,
            subtotalPerParticipant:
              Number(priceSummary?.subtotal?.amount) / numberOfParticipants,
            tax: Number(priceSummary?.tax?.amount),
            totalPrice: priceSummary?.total?.amount
              ? Number(priceSummary?.total?.amount)
              : state.paymentDetails.price,
            payNow: Number(payNow?.total?.amount),
            payLater:
              isFormDisplayPaymentdetailsWithDepositEnabled &&
              payLater?.total?.amount
                ? Number(payLater?.total?.amount)
                : state.paymentDetails.payLater,
          },
          status: FormStatus.IDLE,
        });
      } else {
        const paymentsDetails = await formApi.getPaymentsDetails({
          slot,
          numberOfParticipants,
          rate,
          serviceId,
          couponCode,
          email,
          isFixedPrice: isFixedPrice(payment),
          onError: (error) => {
            errorHandlers.addError(error);
          },
        });

        if (!paymentsDetails) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        const appliedCoupon = paymentsDetails?.couponDetails;
        const finalPrice = paymentsDetails?.finalPrice;

        setState({
          couponInfo: {
            ...state.couponInfo,
            appliedCoupon,
          },
          paymentDetails: {
            ...state.paymentDetails,
            minCharge: finalPrice?.downPayAmount
              ? Number(finalPrice.downPayAmount)
              : state.paymentDetails.minCharge,
            totalPrice: finalPrice?.amount
              ? Number(finalPrice?.amount)
              : state.paymentDetails.price,
          },
          status: FormStatus.IDLE,
        });
      }
    } catch (error) {
      errorHandlers.addError(error);
      setState({ status: FormStatus.IDLE });
      reportError(error);
    }
  };
}

async function previewPrice({
  formApi,
  serviceId,
  resourceId,
  selectedVariants,
}: {
  formApi: FormApi;
  serviceId: string;
  resourceId: string;
  selectedVariants?: SelectedVariants[];
}): Promise<PriceInfo> {
  if (selectedVariants) {
    const bookingLineItems = mapVariantsToLineItems({
      serviceId,
      resourceId,
      selectedVariants,
    });
    if (bookingLineItems.length) {
      const previewPriceResponse = await formApi.previewPrice({
        bookingLineItems,
      });
      return previewPriceResponse?.priceInfo!;
    }
  }
  return {};
}

function mapCouponDiscountToAppliedCoupon(couponDiscount?: AppliedDiscount) {
  const coupon = couponDiscount?.coupon;
  return coupon
    ? {
        couponCode: coupon?.code,
        couponDiscount: coupon?.amount?.amount,
        couponId: coupon?.id,
        couponName: coupon?.name,
      }
    : undefined;
}
